import styled from 'styled-components';


const FourZeroFourStyleWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;

  @media only screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
  }

  .iso404Content {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;

    @media only screen and (max-width: 767px) {
      order: 2;
      margin-top: 20px;
      align-items: center;
      text-align: center;
      flex-direction: column;
    }

    h1 {
      font-size: 84px;
      font-weight: 700;
      line-height: 1;
      margin: 0 0 25px;
    }

    h3 {
      font-size: 24px;
      font-weight: 400;
      margin: 0 0 10px;
      line-height: 1.2;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      margin: 0 0 10px;
    }
  }

  .iso404Artwork {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: ${props => (props['data-rtl'] === 'rtl' ? 'inherit' : '100px')};
    margin-right: ${props => (props['data-rtl'] === 'rtl' ? '100px' : 'inherti')};
    height: 500px;

    @media only screen and (max-width: 767px) {
      margin-left: ${props => (props['data-rtl'] === 'rtl' ? 'inherit' : '0')};
      margin-right: ${props => (props['data-rtl'] === 'rtl' ? '0' : 'inherit')};
    }

    img {
      max-height: 100%;
    }
  }
`;

export default FourZeroFourStyleWrapper;
