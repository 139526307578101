import React from 'react'
import Layout from "../components/App/Layout"
import InternalServerError from '../components/InternalServerError/index'
import SEO from '../components/App/SEO';

const Team = () => {
    return (
        <Layout>
          <SEO
            title="500"
            desc="システムエラーが発生しました。"
            pathname={'/500'}
            type={'Article'}
            shortName="500"
            createdAt="2021-03-02"
            updatedAt="2021-03-02"
            noIndex={true}
            noFollow={true}
          />
          <InternalServerError />
        </Layout>
    );
};

export default Team